import { PlainDocument } from '../../../layouts/plain-document';

export default function TroonJuniorClubBenefits() {
	return (
		<PlainDocument
			slug="troon-junior-club-benefits"
			breadcrumbs={[
				{ href: '/play-troon-programs', title: 'Play Troon Programs' },
				{ href: '/play-troon-programs/troon-junior-club', title: 'Troon Junior Club' },
			]}
		/>
	);
}
